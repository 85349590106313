import React from 'react';
import { FormattedMessage } from 'react-intl';

function WhyChooseUs(props) {
    return (
        <div id="why" class="why-choose-us">
        <div class="container">
            <div class="row section-row align-items-center">
                <div class="col-lg-8 col-md-12">
                    {/* <!-- Section Title Start --> */}
                    <div class="section-title">
                        <h3 class="wow fadeInUp"><FormattedMessage id="why" defaultMessage="why" /></h3>
                        <h2 class="text-anime-style-3"><FormattedMessage id="Why Choose Us Heading" defaultMessage="Why Choose Us Heading" /></h2>
                    </div>
                    {/* <!-- Section Title End --> */}
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    {/* <!-- Why Choose Item Start --> */}
                    <div class="why-choose-us-item wow fadeInUp" data-wow-delay="0.25s">
                        <div class="icon-box">
                            <img src={`${process.env.PUBLIC_URL}/Styles/images/icon-whyus-1.svg`} alt=""/>
                        </div>
                        <h3><FormattedMessage id="Innovation" defaultMessage="Innovation" /></h3>
                        <p><FormattedMessage id="InnovationDesc" defaultMessage="InnovationDesc" />

                        </p>
                    </div>
                    {/* <!-- Why Choose Item End --> */}
                </div>

                <div class="col-lg-4 col-md-6">
                    {/* <!-- Why Choose Item Start --> */}
                    <div class="why-choose-us-item wow fadeInUp" data-wow-delay="0.5s">
                        <div class="icon-box">
                            <img src={`${process.env.PUBLIC_URL}/Styles/images/icon-whyus-2.svg`} alt=""/>
                        </div>
                        <h3><FormattedMessage id="Quality-Focused" defaultMessage="Quality-Focused" /></h3>
                        <p><FormattedMessage id="Quality-FocusedDesc" defaultMessage="Quality-FocusedDesc" /></p>
                    </div>
                    {/* <!-- Why Choose Item End --> */}
                </div>

                <div class="col-lg-4">
                    {/* <!-- Why Choose Item Start --> */}
                    <div class="why-choose-us-item wow fadeInUp" data-wow-delay="0.75s">
                        <div class="icon-box">
                            <img src={`${process.env.PUBLIC_URL}/Styles/images/icon-whyus-3.svg`} alt=""/>
                        </div>
                        <h3><FormattedMessage id="Easy to Use" defaultMessage="Easy to Use" /></h3>
                        <p><FormattedMessage id="Easy to UseDesc" defaultMessage="Easy to UseDesc" />

                        </p>
                    </div>
                    {/* <!-- Why Choose Item End --> */}
                </div>

                <div class="col-md-12">
                    {/* <!-- Why Us Explore Item Start --> */}
                    <div class="why-us-explore-item">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="why-us-section-title">
                                    {/* <!-- Section Title Start --> */}
                                    <div class="section-title">
                                        <h2 class="text-anime-style-3"><FormattedMessage id="Get Started TodayDownload Now!" defaultMessage="Get Started TodayDownload Now!" /></h2>
                                    </div>
                                    {/* <!-- Section Title End --> */}

                                    {/* <!-- Explore Item Icon Start --> */}
                                    <div class="explore-item-icon">
                                        <img src={`${process.env.PUBLIC_URL}/Styles/images/globe.png`} alt=""/>
                                    </div>
                                    {/* <!-- Explore Item Icon End --> */}
                                </div>
                            </div>
                        </div>                        

                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                {/* <!-- Explore Item Content Start --> */}
                                <div class="explore-item-content wow fadeInUp" data-wow-delay="0.25s">
                                    <p><FormattedMessage id="Get Started TodayDownload Now Desc" defaultMessage="Get Started TodayDownload Now! Desc" /></p>
                                </div>
                                {/* <!-- Explore Item Content End --> */}
                            </div>

                            <div class="col-lg-7">
                                {/* <!-- Explore Item Content Start --> */}
                                <div class="explore-item-tags wow fadeInUp" data-wow-delay="0.25s">
                                    <ul>
                                        <li><a href="https://apps.apple.com/us/app/serene-browser-app/id6737122971" class="btn-default"><FormattedMessage id="AppStore" defaultMessage="AppStore" /></a></li>
                                        <li><a href="#" class="btn-default"><FormattedMessage id="PlayStore" defaultMessage="PlayStore" /></a></li>
                                        <li><a href="#" class="btn-default"><FormattedMessage id="Mirror" defaultMessage="Mirror" /></a></li>
                                    </ul>
                                </div>
                                {/* <!-- Explore Item Content End --> */}
                            </div>
                        </div>                        
                    </div>
                    {/* <!-- Why Us Explore Item End --> */}
                </div>
            </div>
        </div>
    </div>
    );
}

export default WhyChooseUs;